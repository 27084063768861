import React from "react";
import Translation from "../translation/translation";
import { changeRegion, changeLang } from "../../reducers/actions/index";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import wallonie from "../../images/wallonie_v.png";
import vlaanderen from "../../images/vlaanderen.png";
import germ from "../../images/ostbelgien-logo.png";
import "./lang.scss";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

class RegionPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      options: ["Wallonie", "Bruxelles", "Vlaanderen"],
      placeholder: "Select your language",
      dropdownOpen: true,
    };
    /* if (typeof window !== 'undefined') { 
            if (localStorage.getItem('qvax-selected-region')) {
                navigate("/register");
            }
        } */
  }

  UNSAFE_componentWillMount() {
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved);
    }
  }

  render() {
    return (
      <div>
        <div className="content">
          {/* <div className="titles">
                        <h1><Translation trl="request_title"/></h1>
                        <h2><Translation trl="select_region_title"/></h2>
                    </div> */}
          <div className="explaination">
            <div className="region">
              <div>NL</div>
              <p>
                Kies hieronder de regio waar u officieel woont (waar uw
                domicilieadres is). Dan kan u zich inschrijven op de
                reservelijst voor Covid-19-vaccinatie in het vaccinatiecentrum
                in uw buurt.
                <br />
                <a href="#nl">Meer lezen... </a>
              </p>
            </div>
            <div className="region">
              <div>FR</div>
              <p>
                Choisissez ci-dessous la région dans laquelle vous êtes
                officiellement domicilié. Vous pourrez ensuite vous inscrire sur
                la liste de réserve pour la vaccination Covid-19 dans un centre
                de vaccination.
                <br />
                <a href="#fr">Lire plus...</a>
              </p>
            </div>
            <div className="region">
              <div>DE</div>
              <p>
                Wählen Sie hier unten die Gemeinschaft/Region aus, in der Sie
                Ihren offiziellen Wohnsitz haben. Anschließend können Sie sich
                in die Reserveliste für die Covid-19 Schutzimpfung des
                Impfzentrums in Ihrer Nähe eintragen.
                <br />
                <a href="#de">Mehr lesen...</a>{" "}
              </p>
            </div>
            <div className="region">
              <div>EN</div>
              <p>
                Choose below the region where you officially live (your legal
                address). You can then join the reserve list for the
                Covid-19-vaccine at the vaccination centre in your
                neighbourhood.
                <br />
                <a href="#en">Read more... </a>
              </p>
            </div>
          </div>

     
          <div className="dropdown-container">
          <h3>Klik hieronder op uw regio en druk op OK / Cliquez sur votre région ci-dessous et appuyez sur OK</h3>
            <div className="select" onClick={() => this.toggleDropdown()}>
              <span className={this.state.dropdownOpen ? "active" : ""}>
                {
                  this.state
                    .selectedOption /* || <Translation trl="select_region"/> */
                }
              </span>
              <div className="icon-container">
                {!this.state.dropdownOpen && <ExpandLessIcon />}
                {this.state.dropdownOpen && <ExpandMoreIcon />}
              </div>
            </div>
            <div
              className={
                this.state.dropdownOpen ? "dropdown active" : "dropdown"
              }
            >
              <div onClick={() => this.selectOption("Vlaanderen", "nl")}>
                {"Vlaanderen"}
                <img src={vlaanderen}></img>
              </div>
              <div onClick={() => this.selectOption("Wallonie", "fr")}>
                {"Wallonie"}
                <img src={wallonie}></img>
              </div>
              <div
                onClick={() =>
                  this.selectOption("Deutschsprachige Gemeinschaft", "de")
                }
              >
                {"Deutschsprachige Gemeinschaft"}
                <img src={germ}></img>
              </div>
              <div onClick={() => this.selectOption("Bruxelles", "fr")}>
                {"Bruxelles / Brussel"}
                <img src="https://elections2018.brussels/sites/default/files/2018-01/logo_gewestelijke-overheidsdienst-brussel_-service-public-regional-de-bruxelles.jpg"></img>
              </div>
            </div>
          </div>
          <div className="actions">
            {/* <button className='secondary'><Translation trl="back"/></button> */}
            <button
              onClick={() => this.nextStep()}
              className={
                this.state.selectedOption ? "primary" : "primary disabled"
              }
            >
              <span>OK</span>
            </button>
          </div>

          <div className="full_text" id="nl">
            <h1>NL</h1>
            <p>
              Dankzij QVAX schrijft u zich vrijwillig in op een reservelijst om
              sneller een Covid-19-vaccin te krijgen. U steekt niemand voorbij,
              maar wordt snel opgeroepen wanneer mensen niet komen opdagen in
              een vaccinatiecentrum. Zo vermijden we dat er vaccins verloren
              gaan. U duidt aan wanneer het vaccinatiecentrum u snel mag
              oproepen voor een vaccin. Het vaccinatiecentrum kan u dan
              gemakkelijk verwittigen wanneer er een mogelijkheid vrijkomt voor
              vaccinatie. Het vaccinatiecentrum houdt zich wel altijd aan de
              prioriteiten van de vaccinatiecampagne en de richtlijnen van elke
              regio. Het kan dus even duren voor u wordt opgeroepen van op de
              reservelijst en mogelijk ontvangt u eerder een uitnodiging tot
              vaccinatie volgens het normale systeem. Door u te registreren in
              QVAX draagt u alvast bij tot een vlotte vaccinatiecampagne.
            </p>
          </div>
          <div className="full_text" id="fr">
            <h1>FR</h1>
            <p>
              Grâce à QVAX, vous pouvez vous inscrire volontairement sur une
              liste de réserve pour bénéficier plus rapidement d'un vaccin
              Covid-19. Vous ne dépassez personne, mais vous comblez les
              absences lorsque des personnes ne se présentent pas à leur
              rendez-vous dans un centre de vaccination et que, de ce fait, des
              vaccins risquent d'être perdus. Vous indiquez simplement quand
              vous êtes disponible pour un vaccin. Votre centre de vaccination
              peut alors facilement vous avertir lorsqu'une possibilité de se
              faire vacciner se présente. Bien entendu, les directives de chaque
              région concernant les groupes prioritaires sont scrupuleusement
              respectées. Il pourrait donc s'écouler un certain temps avant que
              vous ne soyez appelé via la liste de réserve et peut-être même
              recevrez-vous votre invitation via la voie normale avant. En vous
              inscrivant sur QVAX, vous contribuez à accélérer la campagne de
              vaccination.{" "}
            </p>
          </div>
          <div className="full_text" id="de">
            <h1>DE</h1>
            <p>
              Über QVAX tragen Sie sich freiwillig in eine Reserveliste ein, um
              schneller einen Termin für eine Covid-19 Schutzimpfung zu
              erhalten. Sie überholen niemanden in der festgelegten
              Impfreihenfolge, sondern werden kurzfristig kontaktiert, wenn eine
              Person nicht zu einem Impftermin erscheint. Auf diese Weise
              wird vermieden, dass Impfstoff verloren geht. Sie geben an, wann
              das Impfzentrum Sie kurzfristig für eine Impfung kontaktieren
              kann. Das Impfzentrum kann Sie dann ganz einfach benachrichtigen,
              wenn eine Möglichkeit zur Impfung frei wird. Das Impfzentrum
              beachtet stets die Prioritäten der Impfkampagne und die
              Richtlinien der jeweiligen Gemeinschaft/Region. Daher kann es
              einige Zeit dauern, bis Sie von der Reserveliste aufgerufen
              werden, und Sie erhalten möglicherweise schon früher eine
              Einladung zur Impfung über das normale Einladungssystem. Mit der
              Registrierung in QVAX tragen Sie zu einer schnelleren und
              reibungslosen Impfkampagne bei.
            </p>
          </div>
          <div className="full_text" id="en">
            <h1>EN</h1>
            <p>
              Thanks to QVAX, you voluntarily register on a reserve list to get
              a Covid-19 vaccine faster. You don't pass anyone by, but are
              called up quickly when people don't show up at a vaccination
              centre. This way, we avoid losing vaccines. You indicate when the
              vaccination centre can quickly call you up for a vaccine. The
              vaccination centre can then easily notify you when a possibility
              to be vaccinated becomes available. However, the vaccination
              centre always respects the priorities of the vaccination campaign
              and the guidelines of each region. Therefore, it may take some
              time before you are called from the reserve list and you may
              receive an invitation for vaccination under the normal system
              sooner. By registering in QVAX, you are contributing to a swift
              vaccination campaign.
            </p>
          </div>
        </div>
      </div>
    );
  }

  nextStep() {
    navigate("/how", { state: { lang: this.props.lang } });
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  selectOption(option, lang) {
    this.setState({ selectedOption: option });
    this.toggleDropdown();
    this.props.changeLang(lang);
    this.props.changeRegion(option);
  }
}

const mapStateToProps = state => ({
  region: state.region,
  lang: state.lang,
});

const mapDispatchToProps = () => {
  return {
    changeRegion,
    changeLang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(RegionPage);
