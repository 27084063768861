import React, { Component } from "react"
import ContextProvider from "../components/context/contextProvider"
import RegionPage from "../components/region-page/regionPage"
import { createStore } from "redux"
import { Provider } from "react-redux"
import allReducer from "../reducers"
import Cookies from "../components/cookies/cookies";
import MessageAlert from "../components/message-alert/message-alert";
import "../styles/shared.scss"
import NavbarRegion from "../components/navbar/NavbarRegion"

let store
if (typeof window !== "undefined") {
  store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else {
  store = createStore(allReducer)
}
class Region extends Component {
  render() {
    return (
      <ContextProvider>
        <Provider store={store}>
          <NavbarRegion hideAuth={true} />
          <RegionPage
            langSaved={
              this.props.location.state ? this.props.location.state.lang : null
            }
          />
        </Provider>
      </ContextProvider>
    )
  }
}

export default Region
