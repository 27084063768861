import React from "react";
import { connect } from "react-redux";
import Translation from "../translation/translation";
import "./message-alert.scss";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class MessageAlert extends React.Component {
  constructor(props) {
    super(props)
    let hideBanner = false;
    this.state = {
      hideBanner
    }
    if (typeof window !== "undefined") {
      hideBanner = !!localStorage.getItem("qvax-message-accepted")
    }
    this.state = {
      hideBanner,
    }
  }

  render() {
    return this.state.hideBanner ? (
      <div id="empty" />
    ) : (
      <div id="messageAlert">
        <span>
          <Translation trl="message_alert" />
        </span>     
      </div>
    )
  }

  acceptConditions() {
    if (typeof window !== "undefined") {
      localStorage.setItem("qvax-message-accepted", "true");
      this.setState({ hideBanner: true })
    }
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(MessageAlert)
