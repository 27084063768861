import React from "react"
import { connect } from "react-redux"
import Translation from "../translation/translation"
import "./cookies.scss"
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class Cookies extends React.Component {
  constructor(props) {
    super(props)
    let hideBanner = false
    if (typeof window !== "undefined") {
      hideBanner = !!localStorage.getItem("qvax-cookies-accepted")
    }
    this.state = {
      hideBanner,
    }
  }

  render() {
    return this.state.hideBanner ? (
      <div id="empty" />
    ) : (
      <div id="cookies">
        <span>
          <Translation trl="cookies" />
          &nbsp;
          <a
            href={
              this.props.lang === "nl"
                ? `../../NL - Cookies Beleid QVAX.pdf`
                : this.props.lang === "de"
                ? `../../QVAX-Hinweise_zur_Nutzung_von_Cookies.pdf`
                : this.props.lang === "en"
                ? `../../EN-Cookie_Policy_QVAX.pdf`
                : `../../FR - Politique Cookies QVAX.pdf`
            }
            target="_blank"
          >
            <Translation trl="cookie_link" />
          </a>
        </span>
        <button onClick={() => this.acceptCookies()}>
          <Translation trl="got_it" />
        </button>
      </div>
    )
  }

  acceptCookies() {
    if (typeof window !== "undefined") {
      localStorage.setItem("qvax-cookies-accepted", "true")
      this.setState({ hideBanner: true })
    }
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(Cookies)
