import React from "react"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import PermIdentityIcon from "@material-ui/icons/PermIdentity"
import Translation from "../translation/translation"
import { Link, navigate } from "gatsby"
import "./navbar.scss"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import LangSelector from "../langSelector/langSelector"
import logo from "../../images/logo_qvax.svg"
import { connect } from "react-redux"
class NavbarRegion extends React.Component {
  render() {
    return (
      <div className="navbar_region">
        <img src={logo} onClick={() => this.goHome()} />
      </div>
    )
  }

  goHome() {
    if (typeof window !== "undefined") {
      localStorage.removeItem("qvax-selected-region")
      navigate("/region")
    }
  }

  logout() {
    if (typeof window !== "undefined") {
      localStorage.removeItem("qvax-session")
      localStorage.removeItem("SeatersEmbeddedAuthToken")
      navigate("/login")
    }
  }
}
const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(NavbarRegion)
